import { requestMarket } from '@/api'
import { request, requetCommunity } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_MAIN = 'V1/Main'
const ENDPOINT_RECORDCUSTOMIZECONTENTS = 'V1/RecordCustomizeContents'
const ENDPOINT_COMMUNITY_SETPOSTFAVORITE = 'Community/SetPostFavorite'
const ENDPOINT_CUSTOMIZECONTENTSDISPLAY = 'V1/CustomizeContentsDisplay'
const ENDPOINT_ISFIRSTDATEACCESS = 'V1/IsFirstDateAccess'
const ENDPOINT_SAVEDEFAULTTAB = 'V1/SaveDefaultTab'
const ENDPOINT_ISCHANGEALLOWDEFAULTTAB = 'V1/IsChangeAllowDefaultTab'
const ENDPOINT_HASMILEAGENOTIFICATION = 'Member/UserMileageNotificationRedDot'
const ENDPOINT_HASALARMNOTIFICATION = 'Member/UserNotificationBadgeCount'
const ENDPOINT_RECORDDAYLIST = 'V1/RecordDayList'
const ENDPOINT_ISFIRSTMEALRECORD = 'V1/IsDiaryFirstMealRecord'
const ENDPOINT_TOPBANNER = 'V1/TopBanner'

export default {
  namespaced: true,
  state: {
    mealInfo: null,
    workoutInfo: null,
    weightInfo: null,
    noonBodyInfo: null,
    inBodyInfo: null,
    waterInfo: null,
    mensesInfo: null,
    questInfo: [],
    questMealInfo: null,
    questWorkoutInfo: null,
    topBannerListInfo: [],
    recommendContentList: null,
    responseFavorite: null,
    log: '',
    topBannerInfo: {
      IsShow: false,
      ImgURL: ''
    },
    isShowFirstTabTooltip: false,
    isShowPointBadge: false,
    alarmBadgeLength: 0,
    calendarRecordList: null,
    isFirstMealRecord: false,
    challengeUserApi: null,
    isChallengeParticipating: null,
    selectedDatechallengeId: 0
  },
  actions: {
    // 메인 데이터 조회
    async getDiaryMainData ({ state }, payload) {
      let userClickedBannerCode = cookies.get('UserClickedBannerCode')
      if (userClickedBannerCode === undefined) userClickedBannerCode = null
      const resp = await request(ENDPOINT_MAIN, 'post', {
        selectedDay: payload.selectedDay,
        topBannerCodeArrayString: userClickedBannerCode,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.mealInfo = resp.data.mealInfo
        state.workoutInfo = resp.data.workoutInfo
        state.weightInfo = resp.data.weightInfo
        state.noonBodyInfo = resp.data.noonBodyInfo
        state.inBodyInfo = resp.data.inBodyInfo
        state.questInfo.push(resp.data.questMeal, resp.data.questWorkout)
        state.waterInfo = resp.data.waterInfo
        state.mensesInfo = resp.data.menstruationInfo
        // state.topBannerListInfo = resp.data.topBannerList
        state.questMealInfo = resp.data.questMeal
        state.questWorkoutInfo = resp.data.questWorkout
        state.challengeUserApi = resp.data.challengeUserApi
        // 현재 챌린지 참여 여부
        if (state.challengeUserApi !== null && state.challengeUserApi !== undefined && !state.challengeUserApi?.isEnd) {
          state.isChallengeParticipating = true
        } else {
          state.isChallengeParticipating = false
        }
      }
    },
    // 메인 맞춤 콘텐츠 팝업 데이터 조회
    async getRecommendContent ({ state }, payload) {
      let diaryIntroClick = cookies.get('DiaryIntroClick')
      if ('1' === diaryIntroClick) {
        diaryIntroClick = 'DiaryIntroClick'
      } else diaryIntroClick = null

      // 챌린지 모집 배너 클릭 횟수
      let lbChallImageBannerClickCnt = cookies.get('LBChallImageBannerClickCnt')
      lbChallImageBannerClickCnt = lbChallImageBannerClickCnt === undefined || lbChallImageBannerClickCnt === null ? 0 : parseInt(lbChallImageBannerClickCnt)
      if (lbChallImageBannerClickCnt >= 2) {
        if (diaryIntroClick === null) diaryIntroClick = 'LBChallBannerTwiceClick'
        else diaryIntroClick += ',LBChallBannerTwiceClick'
      }
      
      state.recommendContentList = null
      const resp = await request(ENDPOINT_RECORDCUSTOMIZECONTENTS, 'post', {
        selectedDay: payload.selectedDay,
        imageBannerCodeArrayString: diaryIntroClick,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.responseFavorite = null
        state.recommendContentList = resp.data.list
      }
    },
    // 메인 맞춤 콘텐츠 즐겨찾기
    async setFavoriteContent ({ state }, payload) {
      const resp = await requetCommunity(ENDPOINT_COMMUNITY_SETPOSTFAVORITE, 'post', {
        UID: cookies.get('UID'),
        PostID: payload.PostID,
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log + resp.data)
        state.responseFavorite = resp.data.Data
      }
    },
    // 메인 맞춤 콘텐츠 팝업 읽음 처리
    async setRecommendContentRead ({ state }, payload) {
      const resp = await request(ENDPOINT_CUSTOMIZECONTENTSDISPLAY, 'post', {
        diaryUserRecordKey: payload.diaryUserRecordKey,
        contentGroupCode: payload.contentGroupCode,
        contentsIdx: payload.contentsIdx,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log)
      }
    },
    // 메인 상단 배너 이미지 조회
    async getIsFirstDataAccess ({ state }) {
      const resp = await request(ENDPOINT_ISFIRSTDATEACCESS, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log)
        state.topBannerInfo.IsShow = resp.data
        state.topBannerInfo.ImgURL = ''
      }
    },
    // 다이어리 탭을 첫 표기화면으로 설정
    async setSaveDefaultTab ({ state }, payload) {
      const resp = await request(ENDPOINT_SAVEDEFAULTTAB, 'post', {
        isDefaultTab: payload.isDefaultTab,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log)
      }
    },
    // 메인에 다이어리 탭을 첫 표기화면 설정관련 툴팁 표시 여부 조회
    async getIsChangeAllowDefaultTab ({ state }) {
      const resp = await request(ENDPOINT_ISCHANGEALLOWDEFAULTTAB, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log)
        state.isShowFirstTabTooltip = resp.data
      }
    },
    // 적립금 뱃지 표시
    async getIsShowPointBadge ({ state }) {
      const resp = await requestMarket(ENDPOINT_HASMILEAGENOTIFICATION, 'post', {
        mallAccessToken: '',
        uid: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        callLocation: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.isShowPointBadge = resp.data
      }
    },
    // 알림 뱃지 표시
    async getIsShowAlarmBadge ({ state }) {
      const resp = await requestMarket(ENDPOINT_HASALARMNOTIFICATION, 'post', {
        mallAccessToken: '',
        uid: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        callLocation: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.alarmBadgeLength = resp.data
      }
    },
    // 다이어리 메인 슬라이드 캘린더 기록 리스트
    async getMainCalendarRecordList ({ state }, payload) {
      const resp = await request(ENDPOINT_RECORDDAYLIST, 'post', {
        year: payload.year,
        month: payload.month,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log)
        state.calendarRecordList = resp.data
      }
    },
    // 메인 상단 배너 이미지 조회
    async getIsFirstMealRecord ({ state }) {
      const resp = await request(ENDPOINT_ISFIRSTMEALRECORD, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log)
        state.isFirstMealRecord = resp.data
      }
    },
     // 상단 배너
     async getTopBanner ({ state }, payload) {
      let userClickedBannerCode = cookies.get('UserClickedBannerCode')
      if (userClickedBannerCode === undefined) userClickedBannerCode = null
      const resp = await request(ENDPOINT_TOPBANNER, 'post', {
        selectedDay: payload.selectedDay,
        topBannerCodeArrayString: userClickedBannerCode,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log)
        state.topBannerListInfo = resp.data.topBannerList
      }
    }
  },
  mutations: {
    INIT_ALARM_BADGE_LENGTH (state) {
      state.alarmBadgeLength = 0
    },
    SET_CHALLENGEID (state, payload) {
      state.selectedDatechallengeId = Number(payload)
    }
  }
}
